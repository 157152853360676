import { getFloor, getFloorAt } from './geom.js'

/**
 * Attempts to grab the user's location (bluedot, kiosk, etc.) and if none found, then
 * falls back on the map center.
 * @param {Object} bus bus for querying for location
 * @returns {Object} location ({lat, lng, floorId, ordinal, structureId }) (floorId and structureId can be null)
 */
async function getLocation (bus) {
  const location = await bus.getFirst('user/getPhysicalLocation')

  if (location)
    return location

  const position = await bus.get('map/getMapCenter')
  return position
}

/**
 * Allows for flexibility in the manner in which you specify a location. This may call to getVenueData, so you should not
 * call this function until you are in the init stage of your plugin, to ensure the venueDataLoader plugin has had a chance
 * to register its getVenueData listener.
 *
 * @param {Object} app The app object
 * @param {Object} location A location in the map, which can be a {poid} or {lat,lng,ord} or {lat,lng,ordinal} or {lat,lng,floorId}
 * @returns an Endpoint { lat, lng, ordinal, floorId, title }
 */
async function locationToEndpoint (app, location) {
  if (location.poiId)
    return app.bus.get('wayfinder/getNavigationEndpoint', { ep: location.poiId })
  let { lat, lng, ord, ordinal, floorId, title = '' } = location
  if (ordinal === undefined && ord !== undefined)
    ordinal = ord // normalize on "ordinal"
  if (ordinal !== undefined && floorId !== undefined) // we are good to go
    return { lat, lng, ordinal, floorId, title }

  // We are missing either an ordinal or a floorId.. we can get a floor object from either one and retrieve the other
  const structures = await getStructures(app)

  // Note: floor can be legitimately null here - if floorId was null and the location is not within a building...
  const floor = ordinal !== undefined ? getFloorAt(structures, lat, lng, ordinal) : getFloor(structures, floorId)

  if (ordinal !== undefined)
    return { lat, lng, ordinal, floorId: floor ? floor.id : null, title }

  // if ordinal was not defined, then we need a floor, so if floor is still null here, bad dog.
  if (!floor)
    throw Error('Call to locationToEndpoint with no ordinal and no floorId (or an invalid one): ' + floorId)

  return { lat, lng, floorId, ordinal: floor.ordinal, title }
}

const getStructures = async app => app.bus.get('venueData/getVenueData').then(vd => vd.structures)

export {
  getLocation,
  getStructures,
  locationToEndpoint
}
