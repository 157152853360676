import FloatingActionButton from '@locus-labs/mol-mobile-floating-action-button'
import React, { useEffect, useState } from 'react'
import styled, { withTheme } from 'styled-components'

const PanControlsWrapper = styled.div`
  position: absolute;
  display: flex;
  gap: 12px;
  left: -290px;
  bottom: 20px;
`

const MapPanFab = styled(FloatingActionButton)`
  background: ${({ active, theme }) => active ? theme.colors.primaryButton : theme.colors.background};
`

const FloatingActionButtonRotated = styled(FloatingActionButton)` // This is a hack solution, but at this time SVGs without fill are not available from content
  transform: rotate(90deg);
`

const MapPanControlsMobile = withTheme(({ theme, bus, T, delta = 150 }) => {
  const [showPanControls, setShowPanControls] = useState(false)

  useEffect(() => bus.on('app/reset', () => setShowPanControls(false)), [])

  const onPanButtonClick = async () => {
    setShowPanControls((prev) => !prev)
  }

  const mapMoveAction = (move) => {
    switch (move) {
      case 'panUp':
        bus.send('map/pan', { offset: [0, -1 * delta] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panUp' } })
        break
      case 'panDown':
        bus.send('map/pan', { offset: [0, delta] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panDown' } })
        break
      case 'panLeft':
        bus.send('map/pan', { offset: [-1 * delta, 0] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panLeft' } })
        break
      case 'panRight':
        bus.send('map/pan', { offset: [delta, 0] })
        bus.send('appInsights/log', { name: `mapPan click`, properties: { direction: 'panRight' } })
    }
  }
  const onZoomIn = () => bus.send('map/zoomIn')
  const onZoomOut = () => bus.send('map/zoomOut')

  return <>
    <MapPanFab data-cy="MapPanFab" iconName="move.note" active={showPanControls} iconColor={showPanControls ? theme.colors.primaryButtonText : theme.colors.secondaryText} onClick={onPanButtonClick} ariaLabel={T('mapPanControls:Show Map Controls')} aria-pressed={showPanControls} aria-controls={'mapPanControls'}/>
    {showPanControls && <PanControlsWrapper id={'mapPanControls'}>
      <FloatingActionButton data-cy="mobilePanUp" iconName="arrow.up" ariaLabel={T('mapPanControls:Pan Map Up')} onClick={() => mapMoveAction('panUp')}/>
      <FloatingActionButton data-cy="mobilePanDown" iconName="arrow.down" ariaLabel={T('mapPanControls:Pan Map Down')} onClick={() => mapMoveAction('panDown')}/>
      <FloatingActionButtonRotated data-cy="mobilePanLeft" iconName="arrow.down" ariaLabel={T('mapPanControls:Pan Map Left')} onClick={() => mapMoveAction('panLeft')}/>
      <FloatingActionButtonRotated data-cy="mobilePanRight" iconName="arrow.up" ariaLabel={T('mapPanControls:Pan Map Right')} onClick={() => mapMoveAction('panRight')}/>
      <FloatingActionButton data-cy="mobileZoomIn" iconName="zoom.in" ariaLabel={T('mapPanControls:Zoom In')} onClick={onZoomIn}/>
      <FloatingActionButton data-cy="mobileZoomOut" iconName="zoom.out" ariaLabel={T('mapPanControls:Zoom Out')} onClick={onZoomOut}/>
    </PanControlsWrapper>}
  </>
})

export default MapPanControlsMobile
